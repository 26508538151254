@media (min-width: @screen-lg) {
	.container {
		width: 1280px;
	}
}

@media (max-width: 1300px) {
	.container {
		width: 1170px;
	}

	nav.section-header {
		.header-flexbox {
			padding: 15px 0;
			display: flex;
			justify-content: flex-end;

			.nav-button {
				display: flex;
				margin-right: -10px;
			}

			.flexbox-nav-wrapper {
				position: fixed;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				overflow-y: scroll;
				background: #fff;
				z-index: 555;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s;

				.nav-button-close {
					display: block;
				}

				.flexbox-nav-inner {
					display: flex;
					flex-direction: column;
					justify-content: center;
					min-height: 100%;
					padding: 50px;
					margin-right: 0;

					.flexbox-nav {
						flex-direction: column;

						li {
							margin-right: 0px;

							a {
								padding: 20px;
							}
						}

						&:last-child {
							display: none;
						}
					}
				}

				&.active {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}

@media (max-width: @screen-md-max) {
	.container {
		width: 970px;
	}

	section.section-online {
		padding: 80px 0;

		.online-content {
			padding: 20px 20px 20px 0;
			background: rgba(255, 255, 255, 0.9);
		}
	}

	section.section-footer {
		.footer-flexbox {
			flex-direction: column;
			align-items: center;
			text-align: center;

			.flexbox-service {
				margin-top: 30px;

				ul {
					li {
						a {
							justify-content: center;
						}
					}
				}
			}
		}

		.footer-credits {
			text-align: center;
			justify-content: center;
		}

		.alert {
			text-align: center;
		}
	}
}

@media (max-width: @screen-sm-max) {
	.container {
		width: 750px;
	}

	nav.section-header {
		.container {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			&:before, &:after {
				content: none;
			}
		}

		.header-top {
			border-bottom: none;

			ul {
				display: none;
			}
		}

		.header-flexbox {
			border-bottom: none !important;
		}
	}

	section.section-online {
		.online-content {
			width: 100%;
			padding: 20px;
			text-align: center;

			h2 {
				font-size: 40px;
			}
		}
	}

	section.section-offer {
		.section-heading {
			h2 {
				font-size: 40px;
			}
		}
	}

	section.section-about, section.offer-section-content {
		.about-content {
			h2 {
				font-size: 40px;
			}
		}
	}
}

@media (max-width: @screen-xs-max) {
	.container {
		width: 100%;
	}

	.news-flexbox {
		.flexbox-service {
			width: 48%;
		}
	}

	section.section-main-carousel {
		.main-carousel-content {
			.owl-carousel {
				.item {
					h1, h2 {
						font-size: 28px;

						strong {
							font-size: 36px;
						}
					}
				}
			}
		}
	}

	section.section-offer {
		.offer-flexbox {
			.flexbox-service {
				width: 48%;

				&.service-lg {
					width: 100%;
					flex-direction: column;

					.service-image {
						width: 100%;
					}

					.service-description {
						margin-top: 0;
						width: 100%;
					}
				}
			}
		}
	}

	section.static-page-section-content {
		h1 {
			font-size: 40px;
		}

		.image-gallery {
			.gallery-service {
				width: 48%;
			}
		}
	}

	section.oferta-section-content {
		.content-flexbox {
			flex-direction: column;

			.flexbox-body {
				width: 100%;

				h2 {
					font-size: 32px;
					font-weight: 300;
				}
			}

			.flexbox-sidebar {
				margin-top: 50px;
				width: 100%;
			}
		}
	}

	section.kontakt-section-content {
		.content-flexbox {
			flex-direction: column-reverse;
			text-align: center;

			.flexbox-form {
				margin-top: 50px;
				width: 100%;
			}

			.flexbox-service {
				width: 100%;

				ul {
					li {
						a {
							justify-content: center;
						}
					}
				}
			}
		}

		.iframe-wrapper {
			height: 320px;
			width: 100%;

			iframe {
				width: 100%;
				height: 100%;
				border: none;
			}
		}
	}
}

@media (max-width: 500px) {
	
}