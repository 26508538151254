@import url("https://fonts.googleapis.com/css?family=Fira+Sans:300,400,500,600,700,800&subset=latin-ext");

body {
  min-height: 100vh;
  font-family: "Fira Sans", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.no-scroll {
    overflow: hidden;
    max-height: 100vh;
  }

  ul,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none !important;
  }

  .btn {
    font-size: 13px;
    text-transform: uppercase;
    border-radius: 30px;
    padding: 12px 35px 12px 55px;
    position: relative;
    transition: all 0.3s;

    &:before {
      content: "";
      width: 10px;
      height: 10px;
      background: url("../img/pictures/more.svg") center;
      background-size: cover;
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      padding-left: 65px;
    }

    &.btn-default {
      background: @color1;
      border: none;
      color: #fff;
    }
  }

  .ck-editor-text {
    ul,
    ol {
      padding-left: 20px;
    }

    img {
      max-width: 100% !important;
    }

    * {
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  #return-to-top {
    position: fixed;
    z-index: 333;
    bottom: 20px;
    right: 20px;
    border-radius: 4px;
    transition: all 0.3s;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #555;
    transition: all 0.4s;
    visibility: hidden;
    opacity: 0;

    img {
      width: 35%;
    }

    &:hover {
      background: @color1;
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  .m-t-5 {
    margin-top: 5px;
  }
  .m-t-10 {
    margin-top: 10px;
  }
  .m-t-15 {
    margin-top: 15px;
  }
  .m-t-20 {
    margin-top: 20px;
  }
  .m-t-25 {
    margin-top: 25px;
  }
  .m-t-30 {
    margin-top: 30px;
  }
  .m-b-5 {
    margin-bottom: 5px;
  }
  .m-b-10 {
    margin-bottom: 10px;
  }
  .m-b-15 {
    margin-bottom: 15px;
  }
  .m-b-20 {
    margin-bottom: 20px;
  }
  .m-b-25 {
    margin-bottom: 25px;
  }
  .m-b-30 {
    margin-bottom: 30px;
  }
  .p-t-5 {
    padding-top: 5px;
  }
  .p-t-10 {
    padding-top: 10px;
  }
  .p-t-15 {
    padding-top: 15px;
  }
  .p-t-20 {
    padding-top: 20px;
  }
  .p-t-25 {
    padding-top: 25px;
  }
  .p-t-30 {
    padding-top: 30px;
  }
  .p-b-5 {
    padding-bottom: 5px;
  }
  .p-b-10 {
    padding-bottom: 10px;
  }
  .p-b-15 {
    padding-bottom: 15px;
  }
  .p-b-20 {
    padding-bottom: 20px;
  }
  .p-b-25 {
    padding-bottom: 25px;
  }
  .p-b-30 {
    padding-bottom: 30px;
  }
}

.system-alerts {
  width: 100%;
  text-align: center;

  .alert {
    border-radius: 0;
    border: none;
    height: 60px;
    display: flex;
    align-items: center;
    opacity: 0.9;
    transition: all 0.4s;

    .alert-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .img-hidden {
        visibility: hidden;
        opacity: 0;
        height: 18px;
      }

      p {
        color: #fff;
        padding: 0 50px;
        font-family: 15px;
      }

      button {
        color: #fff;
        opacity: 1;
        font-weight: 300;
        float: none;

        img {
          height: 18px;
        }
      }
    }

    &.alert-success {
      background: #76ad21;
    }

    &.alert-info {
      background: #218ead;
    }

    &.alert-warning {
      background: #c52b20;
    }
  }
}

.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .modal-service {
    padding: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    .service-inner {
      background: #fff;
      width: 500px;
      max-width: 100%;
      border-radius: 8px;
      padding: 25px;
      position: relative;

      .service-button {
        position: absolute;
        top: 20px;
        right: 20px;
        border: none;
        background: none;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        .button-bar {
          display: block;
          position: absolute;
          width: 20px;
          height: 2px;
          background: #222;

          &:nth-child(1) {
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            transform: rotate(-45deg);
          }
        }
      }

      .service-content {
        padding-right: 40px;

        .heading {
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          font-weight: 300;
        }
      }

      .content-address {
        margin-top: 20px;
        background: @color1;
        padding: 10px;
        border-radius: 5px;

        ul {
          text-align: center;

          li {
            margin-top: 10px;

            a {
              color: #fff;
              transition: all 0.4s;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 17px;

              img {
                height: 15px;
                margin-right: 7px;
              }
            }

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }

      .service-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn-link {
          padding: 0;
          text-transform: uppercase;
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
  }
}

.news-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .flexbox-service {
    margin-top: 50px;
    width: 32%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    background: #fff;
    position: relative;

    header {
      width: 100%;

      .service-image-wrapper {
        width: 100%;
        padding-top: 55%;
        position: relative;

        .service-image {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 5px;
          display: flex;
          align-items: center;
          overflow: hidden;

          img {
            width: 100%;
          }
        }
      }

      .name {
        margin-top: 15px;
        font-weight: 300;
        font-size: 15px;
        color: #333;
      }

      .small {
        margin-top: 5px;
        font-weight: 300;
        color: @color1;
      }
    }

    footer {
      margin-top: 15px;

      .btn-more {
        font-weight: 700;
        font-size: 12px;
        color: #444;
        text-transform: uppercase;
        display: block;
        color: @color1;
      }
    }

    &.service-hidden {
      margin: 0;
      padding: 0;
      visibility: hidden;
    }
  }
}

.pagination-nav {
  margin: 45px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  li {
    margin: 5px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 35px;
      width: 35px;
      border-radius: 4px;
      color: #333;
      font-weight: 300;
    }

    &.active {
      a {
        background: @color1;
        color: #fff;
      }
    }
  }
}

nav.section-header {
  .header-top {
    padding: 25px 0;
    border-bottom: 1px solid #f0e9e5;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .top-logo {
      img {
        height: 30px;
      }
    }

    ul {
      display: flex;
      align-items: center;

      li {
        a {
          display: flex;
          align-items: center;
          color: @color1;
          font-size: 16px;
          font-weight: 700;

          img {
            height: 17px;
            margin-right: 10px;
          }
        }

        &.divider {
          margin: 0 30px;
          min-width: 1px;
          max-width: 1px;
          height: 20px;
          background: #f0e9e5;
        }
      }
    }
  }

  .header-flexbox {
    .flexbox-nav-wrapper {
      .nav-button-close {
        position: absolute;
        display: none;
        width: 100%;
        top: 20px;
        left: 0;

        .container {
          display: flex;
          justify-content: flex-end;

          &:before,
          &:after {
            content: none;
          }
        }

        button {
          outline: none;
          border: none;
          background: none;
          padding: 15px;
          display: flex;
          justify-content: center;
          align-items: center;

          .button-bar {
            display: block;
            position: absolute;
            width: 30px;
            height: 2px;
            background: #222;

            &:nth-child(1) {
              transform: rotate(45deg);
            }

            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
      }

      .flexbox-nav-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .flexbox-nav {
          display: flex;
          align-items: center;

          li {
            a {
              color: #333;
              display: block;
              padding: 20px;
              letter-spacing: 0.5px;
              transition: all 0.3s;
              display: flex;
              align-items: center;

              img {
                height: 17px;
                margin-right: 10px;
              }

              &:hover {
                color: @color1;
              }

              &.active {
                color: @color1;
                font-weight: 700;
              }
            }

            &.highlight {
              a {
                background: @color1;
                color: #fff;

                &:hover {
                  background: darken(@color1, 10%);
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }

    .nav-button {
      padding: 10px;
      border: none;
      background: none;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      outline: none;
      display: none;

      .button-bar {
        height: 2px;
        width: 24px;
        background: @color1;
        border-radius: 10px;
        transition: all 0.4s;

        &:nth-child(2) {
          margin-top: 4px;
          width: 27px;
        }

        &:nth-child(3) {
          margin-top: 4px;
          width: 30px;
        }
      }

      &:hover,
      &:focus {
        .button-bar {
          width: 30px !important;
        }
      }
    }

    &.subpage-border {
      border-bottom: 1px solid #f0e9e5;
    }
  }
}

section.section-main-carousel {
  height: 543px;
  display: flex;
  align-items: center;
  position: relative;

  .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    filter: grayscale(50%);

    video {
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .main-carousel-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fe8c00; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #f83600,
      #fe8c00
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #f83600,
      #fe8c00
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    opacity: 0.7;
    filter: grayscale(70%);
  }

  .main-carousel-content {
    position: relative;
    text-align: center;

    .owl-carousel {
      .item {
        padding: 50px 0;

        h1,
        h2 {
          font-size: 40px;
          font-weight: 300;
          color: #fff;

          strong {
            font-size: 60px;
          }
        }
      }

      .owl-stage {
        display: flex;
        align-items: center;
      }
    }
  }
}

section.section-online {
  padding: 100px 0;
  position: relative;

  .img-absolute {
    position: absolute;
    top: 0;
    right: 100px;
    height: 100%;
  }

  .online-content {
    position: relative;
    width: 50%;

    h2 {
      font-size: 51px;
      font-weight: 300;
    }

    h3 {
      margin: 25px 0;
      color: @color1;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 24px;
    }

    p {
      font-weight: 300;
      font-size: 16px;
    }

    .btn {
      margin-top: 25px;
    }
  }
}

section.section-offer {
  padding: 100px 0;
  background: lighten(@gray-lighter, 4%);
  position: relative;

  .offer-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url("../img/backgrounds/offer-background.svg") center;
    background-size: cover;
    background-attachment: fixed;
    filter: grayscale(100%);
    opacity: 0.08;
  }

  .section-heading {
    text-align: center;
    position: relative;

    h2 {
      font-size: 51px;
      font-weight: 300;
    }

    p {
      font-weight: 300;
      font-size: 16px;
    }
  }

  .offer-flexbox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;

    .flexbox-service {
      margin-top: 50px;
      display: block;
      width: 23%;

      &.service-lg {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(255, 255, 255, 0.8);

        .service-image {
          width: 48.666666666666666666666666666667%;
        }

        .service-description {
          margin-top: 0;
          width: 48.666666666666666666666666666667%;
          padding: 30px 30px 30px 0;

          h3 {
            font-size: 24px;
          }

          p {
            width: 100%;
          }
        }
      }

      .service-image {
        border: 1px solid @gray-lighter;
        width: 100%;
        height: 230px;
        border-radius: 4px;
        transition: all 0.3s;
      }

      .service-description {
        margin-top: 20px;

        h3 {
          color: #555;
          color: @color1;
          font-size: 18px;
          text-transform: uppercase;
        }

        p {
          width: 70%;
          margin-top: 10px;
          color: #333;
          font-size: 13px;
          font-weight: 300;
        }
      }

      &:hover {
        .service-image {
          border: 1px solid @color1;
        }
      }
    }
  }
}

section.section-about,
section.offer-section-content {
  padding: 100px 0;
  text-align: center;

  .about-content {
    position: relative;

    h2 {
      font-size: 51px;
      font-weight: 300;
    }

    h3 {
      margin: 25px 0;
      color: @color1;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 24px;
    }

    p {
      font-weight: 300;
      font-size: 16px;
    }

    .btn {
      margin-top: 25px;
    }
  }
}

section.section-cta {
  padding: 50px 0;
  background: @color1;

  h2 {
    color: #fff;
    text-align: center;
  }
}

section.section-footer-cta {
  background: url(../img/backgrounds/fotolia_99586554.jpg) center fixed;
  background-size: cover;
  padding: 70px 0;
  text-align: center;
}

section.section-footer-cta .cta-content h2 {
  font-size: 51px;
  font-weight: 300;
  color: #fff;
}

section.section-footer-cta .cta-content p {
  margin-top: 10px;
  font-weight: 300;
  font-size: 16px;
  color: #fff;
}

section.section-footer-cta .cta-content .btn {
  margin-top: 30px;
}

section.subpage-section-heading {
  background: url("../img/pictures/online.svg") center;
  background-size: cover;
  padding: 50px 0;

  h1 {
    text-align: center;
    color: #fff;
  }
}

section.aktualnosci-section-content {
  padding-top: 20px;
}

.backlink {
  display: inline-block;
  margin-top: 5px;
  padding: 3px 10px;
  border-radius: 3px;
  background: @color1;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;

  &:hover {
    color: #fff;
  }
}

section.static-page-section-content {
  padding: 70px 0;

  h1 {
    text-align: center;
    font-size: 51px;
    font-weight: 300;
  }

  p {
    margin-top: 20px;
    font-weight: 300;
    font-size: 16px;
  }

  .main-image {
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
    margin-bottom: 30px;
  }

  .image-gallery {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 50px;

    .gallery-service {
      width: 23%;
      margin-top: 20px;

      .service-image-wrapper {
        width: 100%;
        padding-top: 50%;
        position: relative;

        .service-image {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          border-radius: 5px;
          overflow: hidden;

          img {
            width: 100%;
          }
        }
      }

      &.service-hidden {
        margin: 0;
        padding: 0;
        visibility: hidden;
      }
    }
  }
}

section.oferta-section-content {
  padding: 70px 0;

  .content-flexbox {
    display: flex;
    justify-content: space-between;

    .flexbox-body {
      width: 63%;

      h2 {
        font-size: 36px;
        font-weight: 300;
      }

      h3 {
        margin-top: 20px;
        color: @color1;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 24px;
      }

      p {
        margin-top: 20px;
        font-weight: 300;
        font-size: 16px;
      }

      ul {
        margin-top: 20px;
        padding-left: 18px;
        list-style: none;

        li {
          margin-top: 5px;
          font-weight: 300;
          font-size: 16px;
          display: flex;

          &:before {
            content: "\2022";
            color: @color1;
            font-weight: bold;
            width: 1em;
            margin-left: -1em;
          }

          a {
            display: flex;
            align-items: center;
            color: #333;
            transition: all 0.3s;

            img {
              height: 15px;
              margin-right: 7px;
            }

            &:hover {
              color: @color1;
            }
          }
        }
      }
    }

    .flexbox-sidebar {
      width: 32%;

      .sidebar-service-wrapper {
        margin-bottom: 30px;
        width: 100%;
        position: relative;
        border-radius: 10px;
        overflow: hidden;

        .sidebar-service {
          width: 100%;
          padding-top: 56%;
          display: block;

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
          }
        }
      }

      .heading {
        margin-bottom: 25px;
        font-size: 15px;
        text-align: center;
      }

      ul {
        li {
          a {
            display: block;
            padding: 5px 10px;
            border-radius: 3px;
            color: #333;
            font-weight: 300;
            font-size: 16px;
            transition: all 0.3s;
          }

          &:nth-child(odd) {
            a {
              background: @gray-lighter;
            }
          }

          &:hover {
            a {
              color: @color1;
            }
          }

          &.active {
            a {
              background: @color1;
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}

section.kontakt-section-content {
  .content-flexbox {
    padding: 50px 0;
    display: flex;
    justify-content: space-between;

    .flexbox-form {
      width: 47%;

      .form-group {
        label {
          font-weight: 400;
          font-size: 12px;
        }
      }

      input[type="text"],
      input[type="email"],
      textarea {
        box-shadow: none;
      }

      textarea {
        min-width: 100%;
        max-width: 100%;
      }

      .checkbox-service {
        margin-top: 15px;
        margin-bottom: 0;
        cursor: pointer;
        width: 100%;
        display: block;

        .service-inner {
          display: flex;
          margin-top: 0;
          font-weight: 400;
          text-align: left;
          font-size: 13px;

          &:before {
            content: "";
            display: block;
            margin-top: 3px;
            margin-right: 12px;
            height: 12px;
            min-width: 12px;
            max-width: 12px;
            background: url("../img/pictures/success.svg") center;
            transition: all 0.4s;
          }
        }

        input {
          visibility: hidden;
          position: absolute;
        }
      }

      .checkbox-service > input:checked + .service-inner {
        &:before {
          background: url("../img/pictures/success-fill.svg") center;
        }
      }

      .info {
        margin-top: 20px;

        p {
          font-size: 12px;
          text-align: left;
        }
      }

      .recaptcha-wrapper {
        margin-top: 20px;
      }

      .btn {
        margin-top: 30px;
      }
    }

    .flexbox-service {
      width: 47%;

      h2 {
        font-weight: 300;
        font-size: 34px;
      }

      address {
        font-size: 16px;
        font-weight: 300;
        margin: 30px 0 0 0;
      }

      .service-card {
        margin-top: 30px;
        padding: 20px;
        background: @gray-lighter;
        border-radius: 6px;

        p {
          font-size: 16px;
          font-weight: 300;

          strong {
            font-size: 19px;
            color: @color1;
          }
        }
      }

      ul {
        margin-top: 30px;

        li {
          margin-bottom: 10px;

          a {
            display: flex;
            align-items: center;
            color: @color1;
            font-size: 19px;
            font-weight: 700;

            img {
              height: 22px;
              margin-right: 10px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .iframe-wrapper {
    height: 320px;
    width: 100%;

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}

section.section-footer {
  padding: 50px 0;
  background: #1b1918;
  position: relative;

  .footer-flexbox {
    display: flex;
    justify-content: space-between;
    position: relative;

    .flexbox-service {
      margin-top: 30px;

      ul {
        li {
          margin-top: 10px;

          &.heading {
            margin-top: 0;
            padding-bottom: 10px;
            font-size: 16px;
            color: #ddd;
          }

          a {
            font-size: 12px;
            color: #999;
            transition: all 0.4s;
            display: flex;
            align-items: center;

            img {
              height: 15px;
              margin-right: 7px;
            }

            &:hover {
              color: @color1;
            }
          }

          p {
            font-size: 17px;
            color: #999;
            display: flex;
            align-items: center;

            img {
              height: 15px;
              margin-right: 7px;
            }
          }
        }

        &.contact {
          li {
            a {
              color: @color1;
              font-size: 17px;
            }
          }
        }
      }
    }
  }

  .footer-credits {
    margin-top: 50px;
    padding-top: 50px;
    border-top: 1px solid #232426;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;

    p {
      font-size: 12px;
      color: #999;
      font-weight: 300;
      margin-top: 10px;

      a {
        color: @color1;
      }
    }

    .credits-logo {
      height: 20px;
    }
  }

  .alert {
    position: relative;
    margin-top: 50px;
    padding: 50px 0 0 0;
    background: none;
    border: none;
    border-top: 1px solid #232426;

    p {
      font-size: 12px;
      color: #999;
      font-weight: 300;

      a {
        color: @color1;
        cursor: pointer;
      }
    }
  }
}
